/* fichier JS de base commun quelques soient les versions du projet web */

// charge bootstrap nécessite jquery et popper
import 'bootstrap/js/dist/collapse' // directement déclaré dans le fichier de config webpack base
import 'bootstrap/js/dist/dropdown' // directement déclaré dans le fichier de config webpack base
import 'bootstrap/js/dist/util' // directement déclaré dans le fichier de config webpack base
//import 'bootstrap' // directement déclaré dans le fichier de config webpack base
import 'lazysizes' // lazy loading https://github.com/aFarkas/lazysizes
lazySizes.cfg.expand = 80
lazySizes.cfg.expFactor = 1

// pas de ./ car on résout dans theme donc déjà déclaré dans resolve.modules dans webpack.base.js
import 'bootstrap/scss/bootstrap'

// Styles template
import 'font-awesome/css/font-awesome.css'
import 'css/on3step-style.scss'

// Styles librairies
//import 'toastr/toastr.scss'

// Styles custom
import './scss/custom'

// Plugins template
import 'script-loader!plugin/sticky.js'
import 'script-loader!js/main.js'

// Scripts librairies
//import 'toastr/toastr.js'
import 'script-loader!./js/libs/utils.js'

// lazysizes : add simple support for background images :
document.addEventListener('lazybeforeunveil', function(e) {
  var bg = e.target.getAttribute('data-bg')
  if ( bg ) e.target.style.backgroundImage = 'url(' + bg + ')'
})

// Actions principales communes à toutes les pages
$(document).ready(function() {

  extendClick()

})
